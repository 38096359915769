<script>
  import Util from "../common/Util/Utility";
  import { connection, loggedInUserId, transactions } from "../store";
  import Cookies from "js-cookie";
  import { db } from "../config";
  import { addMinutes } from "date-fns";
  import goPrint from "../print";
  import * as timeago from "timeago.js";
  import { transactions as transactionLocalX } from "../store";
  import { Tabs, Tab, TabList, TabPanel } from "svelte-tabs";
  import CardDetail from "../components/CardDetail.svelte";
  import CardHistory from "../components/CardHistory.svelte";
  import Layout from "../common/layout/Layout.svelte";
  import Loading from "../common/Loading.svelte";
  import utils from "../utils";
  import { printType } from "../common/enum/printType";

  let storeName = localStorage.getItem("StoreName");
  let storeId = localStorage.getItem("idStore");

  const tolerance = 5;
  let showLimit = 50;

  let history = [],
    historyPrinted = [];
  let trans = [];
  let sectionId = "";
  let needToPrint = [];
  let dataFiltered = [];
  let isLoading;
  let allTrans = [];

  let searchKeyword = "";

  $: isPrinted = false;

  // let transactionsLocal = $transactionLocalX;

  // $: if ($transactionLocalX.length > 0) {
  //   // filter 3 jam
  //   // const filteredTrans = $transactionLocalX.filter((tr) => {
  //   //   var now = new Date().getTime();
  //   //   var local = new Date(tr.createdAtOnLocal).getTime();
  //   //   var minutes = (now - local) / 1000 / 60;
  //   //   return minutes <= 240;
  //   // });
  //   // transactionLocalX.set(filteredTrans);
  // }

  if (storeId && storeId != null) {
    // isLoading.toggle();
    connection.subscribe((server) => {
      console.log("INIT SERVER TRANSACTION SUBSCRIBE ::", new Date());
      server.subscribe("transactions.findTodayByStore", storeId);

      console.log("INIT TRANSACTION REACTIVE ::", new Date());
      let transCollection = server.collection("transactions").reactive();
      console.log("DONE TRANSACTION REACTIVE ::", new Date());

      transCollection.onChange(async (data) => {
        console.log("INIT RUN onTransactionChanged  ::", new Date());

        // data = data.sort((a, b) => a.createdAtOnLocal + b.createdAtOnLocal);
        // console.log("data", data);
        // trans = data;
        onTransactionChanged(data);
      });
    });
  }

  let state = 0;
  function onTransactionChanged(data) {
    // transactionsLocal = $transactionLocalX;
    if (state == 0) {
      state = 1;
      console.log(
        "RUN onTransactionChanged -> map trans codes  ::",
        new Date()
      );
      // const existingCodes = transactionsLocal.map((it) => it.code);

      console.log(
        "RUN onTransactionChanged -> filter 1 trans codes  ::",
        new Date()
      );
      let newest = data;
      // .filter((it) => !existingCodes.includes(it.code))
      // .map((it) => ({ ...it, count: 0 }));

      console.log(
        "RUN onTransactionChanged -> filter 2 trans codes  ::",
        new Date()
      );
      newest = newest.filter((curr) => {
        const countItemsForPrint = curr.items.filter(
          (it) =>
            !utils.checkIfKeyExist(it.category, "printable") ||
            it.category.printable.includes(printType.value)
        ).length;
        const countBonusForPrint = curr.promos.filter((it) =>
          it.bonus.some(
            (bon) =>
              !utils.checkIfKeyExist(bon.category, "printable") ||
              bon.category.printable.includes(printType.value)
          )
        ).length;
        return countItemsForPrint > 0 || countBonusForPrint > 0;
      });

      const tempTrans = [...newest];
      allTrans = tempTrans;
      // transactionLocalX.set(tempTrans);
      showHistory(tempTrans);
      print(tempTrans);
    } else {
      setTimeout(() => {
        state = 0;
        onTransactionChanged(data);
      }, 1000);
    }
  }

  function showHistory(_trans) {
    console.log("RUN showHistory -> sort slice  ::", new Date(), _trans);
    let tempTrans1 = _trans;
    let tempTrans2 = _trans;

    history = tempTrans1
      .sort((a, b) => {
        var dateA = new Date(a.createdAtOnLocal);
        var dateB = new Date(b.createdAtOnLocal);
        return dateA - dateB;
      })
      .filter((tr) => tr[printType.propertyName] < 1)
      .slice(0, showLimit);

    historyPrinted = tempTrans2
      .sort((a, b) => {
        var dateA = new Date(a.createdAtOnLocal);
        var dateB = new Date(b.createdAtOnLocal);
        return dateB - dateA;
      })
      .filter((tr) => tr[printType.propertyName] >= 1)
      .slice(0, showLimit);

    console.log(
      "DONE showHistory -> sort slice  ::",
      new Date(),
      history,
      historyPrinted
    );
  }

  function print(_trans) {
    console.log("RUN print func ::", new Date());
    setTimeout(() => {
      console.log("RUN print -> filter trans  ::", new Date());
      const transactions = _trans.filter((tr) => {
        if (tr[printType.propertyName] < 1) {
          // var now = new Date().getTime();
          // var local = new Date(tr.createdAtOnLocal).getTime();
          // var minutes = (now - local) / 1000 / 60;
          // return (
          //   minutes <= tolerance &&
          //   !needToPrint.map((tp) => tp.code).includes(tr.code) &&
          //   tr[printType.propertyName] < 1
          // );
          return (
            !needToPrint.map((tp) => tp.code).includes(tr.code) &&
            tr[printType.propertyName] < 1
          );
        } else return false;
      });

      console.log("RUN print -> push trans  ::", new Date());
      if (transactions.length > 0 && needToPrint.length == 0) {
        transactions.forEach((trans) => {
          if (!needToPrint.map((tp) => tp.code).includes(trans.code)) {
            needToPrint.push(trans);
          }
        });
        needToPrint = needToPrint;
        onChangeNeedToPrint();
      }
    }, 3000);
  }

  const onChangeNeedToPrint = () => {
    if (needToPrint.length > 0) {
      // resolve need to print
      const existingCodes = $transactionLocalX;

      needToPrint = needToPrint.filter(
        (it) => !existingCodes.includes(it.code)
      );

      needToPrint = needToPrint.sort((a, b) => {
        var dateA = new Date(a.createdAtOnLocal);
        var dateB = new Date(b.createdAtOnLocal);
        return dateA - dateB;
      });

      console.log("needtoprint", needToPrint);
      finalPrint();
    }
  };

  function finalPrint() {
    if (needToPrint.length > 0) {
      const curr = needToPrint[0];

      console.log("INIT PRINT::", new Date());
      setTimeout(function () {
        console.log("INIT PRINT 1 ::", new Date());

        goPrint([curr], function () {
          console.log("Callback PRINT 1 ::", new Date());

          setTimeout(function () {
            console.log("INIT PRINT 2 ::", new Date());

            goPrint([curr], function (codes) {
              console.log("Callback PRINT 2 ::", new Date());

              const code = codes[0];
              const found = needToPrint.find((l) => l.code == code);
              // if (found) {
              //   const count = found.count + 1;
              //   const tempTrans = $transactionLocalX.filter(
              //     (tr) => tr.code != code
              //   );
              //   tempTrans.push({ ...found, [printType.propertyName]: 1, count: count });
              //   transactionLocalX.set(tempTrans);
              // }
              // showHistory();
              let hasPrinted = $transactionLocalX || []
              hasPrinted.push(code)
              transactionLocalX.set(hasPrinted);
              updatePrintCount(
                found["id"],
                found[printType.propertyName] ? found[printType.propertyName] + 1 : 1
              );
              onChangeNeedToPrint();

              state = 0;
              console.log("DONE PRINT 2 ::", new Date());
            });
          }, 2000);
        });
      }, 5000);
    } else {
      state = 0;
    }
  }

  function updatePrintCount(_id, count) {
    // console.log(_id, count);
    $connection
      .call("transactions.update.printCount", _id, count, printType.value)
      .then((e) => {
        console.log(e);
      });
  }

  // setInterval(() => {
  //   print();
  //   showHistory();
  // }, 5000);

  function search() {
    const keyword = searchKeyword.trim().toLowerCase();
    if (keyword != "") {
      historyPrinted = allTrans.filter((e) =>
        e.code.toLowerCase().includes(keyword)
      );
    } else {
      showHistory(allTrans);
    }
  }
</script>

<Loading text="Loading..." bind:this={isLoading} />
<Layout>
  <div class="relative p-4 pb-20">
    <div
      class="absolute right-5 items-center z-10 cursor-pointer"
      on:click={() => {
        $connection.logout();
        loggedInUserId.set(null);
        Cookies.set("token", null);
        console.log($connection);
      }}
    >
      <h5 class="text-md font-medium text-dark-second">Logout</h5>
    </div>
    <h4
      class="absolute z-0 top-5 left-0 right-0 text-center text-md font-medium text-dark-second"
    >
      {storeName} - Transactions
    </h4>

    <div class="flex flex-wrap justify-center items-center my-14">
      <div class="max-w-3xl w-full flex flex-col justify-center items-center">
        <div class="w-full mt-4">
          {#if trans == null}
            <div class="relative p-4 mb-2">
              <div class="flex justify-between items-center">
                <h4 class="text-md font-medium text-gray-800">Loading...</h4>
              </div>
            </div>
          {/if}

          {#if allTrans.length == 0 && searchKeyword == ""}
            <div class="relative p-4 mb-2">
              <div class="flex justify-between items-center">
                <h4 class="text-md font-medium text-gray-800">
                  No History Found
                </h4>
              </div>
            </div>
          {:else}
            <Tabs>
              <TabList>
                <Tab>
                  Not Printed ({history.length})
                </Tab>
                <Tab>Printed ({historyPrinted.length})</Tab>
              </TabList>

              <TabPanel>
                {#if history.length == 0}
                  <div class="relative p-4 mb-2">
                    <div class="flex justify-between items-center">
                      <h4 class="text-md font-medium text-gray-800">
                        No History Found
                      </h4>
                    </div>
                  </div>
                {:else}
                  {#each history as data}
                    <CardHistory
                      {data}
                      on:click={() => {
                        if (sectionId == data["id"]) {
                          sectionId = "";
                        } else {
                          sectionId = data["id"];
                        }
                      }}
                      printCount={data[printType.propertyName]}
                      on:printTransaction={() => {
                        goPrint([data], function () {
                          console.log("1st print");
                          setTimeout(function () {
                            console.log("2nd print");
                            goPrint([data], async (code) => {
                              // const tempOrder = $transactionLocalX.filter(
                              //   (t) => t.code != code
                              // );
                              const found = allTrans.find(
                                (t) => t.code == code
                              );
                              updatePrintCount(
                                found["id"],
                                found[printType.propertyName]
                                  ? found[printType.propertyName] + 1
                                  : 1
                              );
                              // if (found) {
                              //   tempOrder.push({
                              //     ...data,
                              //     [printType.propertyName]: found[printType.propertyName] + 1,
                              //     count: found.count + 1,
                              //   });
                              //   transactionLocalX.set(tempOrder);
                              // }
                              showHistory(allTrans);
                            });
                          }, 3000);
                        });
                      }}
                    />
                    {#if sectionId === data["id"]}
                      <CardDetail {data} />
                    {/if}
                  {/each}
                {/if}
              </TabPanel>

              <TabPanel>
                <div class="relative flex flex-wrap items-stretch mb-4">
                  <span
                    class="z-10 h-full leading-snug font-normal text-center text-gray-400 absolute bg-transparent rounded text-base items-center justify-center w-8 pl-3 py-3"
                  >
                    <i class="bx bx-search" />
                  </span>
                  <input
                    bind:value={searchKeyword}
                    type="text"
                    placeholder="Search here..."
                    on:input={search}
                    class="px-3 py-3 placeholder-gray-400 text-gray-700 relative bg-white rounded text-sm shadow outline-none focus:outline-none focus:shadow-outline w-full pl-10 dark:bg-dark-third dark:text-white"
                  />
                </div>
                {#if (historyPrinted.length == 0 && searchKeyword == "") || historyPrinted.length == 0}
                  <div class="relative p-4 mb-2">
                    <div class="flex justify-between items-center">
                      <h4 class="text-md font-medium text-gray-800">
                        No History Found
                      </h4>
                    </div>
                  </div>
                {:else}
                  {#each historyPrinted as data}
                    <CardHistory
                      {data}
                      on:click={() => {
                        if (sectionId == data["id"]) {
                          sectionId = "";
                        } else {
                          sectionId = data["id"];
                        }
                      }}
                      printCount={data[printType.propertyName]}
                      on:printTransaction={() => {
                        goPrint([data], function () {
                          console.log("1st print");
                          setTimeout(function () {
                            console.log("2nd print");
                            goPrint([data], async (code) => {
                              // const tempOrder = $transactionLocalX.filter(
                              //   (t) => t.code != code
                              // );
                              const found = allTrans.find(
                                (t) => t.code == code
                              );
                              updatePrintCount(
                                found["id"],
                                found[printType.propertyName]
                                  ? found[printType.propertyName] + 1
                                  : 1
                              );
                              // if (found) {
                              //   tempOrder.push({
                              //     ...data,
                              //     [printType.propertyName]: found[printType.propertyName] + 1,
                              //     count: found.count + 1,
                              //   });
                              //   transactionLocalX.set(tempOrder);
                              // }
                              showHistory(allTrans);
                            });
                          }, 3000);
                        });
                      }}
                    />
                    {#if sectionId === data["id"]}
                      <CardDetail {data} />
                    {/if}
                  {/each}
                {/if}
              </TabPanel>
            </Tabs>
          {/if}

          <!-- 
				<div class="flex flex-row space-x-5">
					<div class="flex-1">
						<p>Telah di Print</p>
					
					</div>
					<div class="flex-1">
						<p>Belum di Print</p>
						{#each history.filter((e) => e[printType.propertyName] == 0) as data}
							<div class="relative mb-2">
								<div class="flex">
									<div
										class="flex-1 p-4 relative border border-gray-200 bg-white shadow-md rounded-tl-lg rounded-bl-lg cursor-pointer hover:bg-gray-100"
										on:click="{() => {
											if (sectionId == data['id']) {
												sectionId = '';
											} else {
												sectionId = data['id'];
											}
										}}"
									>
										{#if data["requestToVoid"] && data["status"] != "VOIDED"}
											<div
												class="px-4 py-1 flex items-center rounded-lg bg-yellow-500 text-white mb-2 font-medium"
											>
												<i class="bx bx-error-circle mr-1"></i> Requested Void
											</div>
										{/if}
										<div class="flex justify-between items-center">
											<h4 class="text-md font-medium text-gray-800">
												Transaction #{data["code"].toUpperCase()}
											</h4>
											<span class="text-sm text-gray-500">
												{timeago.format(
													new Date(data["createdAtOnLocal"]),
												)}</span
											>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-sm text-gray-800">
												Order No: <span class="font-medium"
													>{data["orderNo"] ? data["orderNo"] : "-"}</span
												>
											</h4>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-sm text-gray-800">
												Status: <span
													class="font-medium {data['status'] != 'SUCCESS'
														? 'text-red-500'
														: 'text-green-500'}">{data["status"]}</span
												>
											</h4>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-sm text-gray-800">
												Items ({data["items"].reduce(
													(sumAdd, itemAdd) => sumAdd + itemAdd.amount,
													0,
												)})
											</h4>
										</div>
										<div class="flex space-x-2 items-center">
											<i class="bx bx-user"></i>
											<h4 class="text-sm  text-gray-800">
												{data["customer"]["name"]
													? data["customer"]["name"]
													: "-"}
											</h4>
										</div>
										<div class="flex space-x-2 items-center">
											<i class="bx bx-phone"></i>
											<h4 class="text-sm  text-gray-800">
												{data["customer"]["phone"]
													? data["customer"]["phone"]
													: "-"}
											</h4>
										</div>
										<p
											class="absolute right-4 bottom-4 text-lg text-gray-800 font-medium m-0"
										>
											{Util.formatRupiah(data["total"], "Rp .")}
										</p>
									</div>
									<div
										class="bg-primary rounded-tr-lg rounded-br-lg flex items-center justify-center px-4 hover:opacity-90 cursor-pointer"
										on:click="{() => {
											// handlePrint(data);
											// console.log(data);
											// tempTransaction = {};
											// tempTransaction = data;
											// tempTransaction = tempTransaction;
											// modalPrint.toggle();
											// Printy($store, data['items'], data, data['promos']);
											// push('/receipt/print');
											goPrint([data], function () {
												console.log('1st print');
												setTimeout(function () {
													console.log('2nd print');
													goPrint([data], async (code) => {
														const tempOrder = $transactionLocalX.filter(
															(t) => t.code != code,
														);
														const found = $transactionLocalX.find(
															(t) => t.code == code,
														);
														updatePrintCount(
															found['id'],
															found[printType.propertyName] ? found[printType.propertyName] + 1 : 1,
														);
														if (found) {
															tempOrder.push({
																...data,
																[printType.propertyName]: found[printType.propertyName] + 1,
																count: found.count + 1,
															});
															transactionLocalX.set(tempOrder);
														}
														history = history;
													});
												}, 3000);
											});
										}}"
									>
										<i class="bx bx-printer text-2xl text-white"></i>
										<span class="text-white ml-1">{data[printType.propertyName]}</span>
									</div>
								</div>
							</div>
							{#if sectionId === data["id"]}
								<div class="flex justify-end mb-2">
									<div
										class="border border-gray-200 bg-white rounded-md shadow-md w-2/3 flex flex-col p-4 space-y-2"
									>
										{#each data["items"] as product}
											<div class="flex justify-between items-center">
												<h4 class="text-md text-gray-800 font-medium">
													({product["amount"]})
													<span class="text-primary">{product["name"]}</span>
												</h4>
												<p class="text-md text-gray-800 font-medium">
													{Util.formatRupiah(product["price"], "Rp .")}
												</p>
											</div>
											{#each product["additionals"] as additionals}
												{#each additionals["items"] as additional}
													<div
														class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2"
													>
														<h4 class="text-sm text-gray-800 font-medium">
															{additional["nameParent"]
																? additional["nameParent"]
																: ""} - {additional["name"]}
														</h4>
														<p class="text-md text-green-500">
															+ {Util.formatRupiah(additional["price"], "Rp .")}
														</p>
													</div>
												{/each}
											{/each}
											{#if product["toppings"].length > 0}
												{#each product["toppings"] as topping}
													<div
														class="border-l-2 border-green-500 flex items-center justify-between pl-2 ml-2"
													>
														<h4 class="text-sm text-gray-800 font-medium">
															{topping["name"]}
														</h4>
														<p class="text-md text-green-500">
															+ {Util.formatRupiah(topping["price"], "Rp .")}
														</p>
													</div>
												{/each}
											{/if}
										{/each}
										<hr class="my-3" />
										<div class="flex justify-between items-center">
											<h4 class="text-sm text-gray-800 font-medium">
												Subtotal
											</h4>
											<p class="text-md text-gray-800 font-medium">
												{Util.formatRupiah(data["subtotal"], "Rp .")}
											</p>
										</div>
										<div class="flex justify-between">
											<h4 class="text-sm text-gray-800 font-medium">Diskon</h4>
											<div class="flex flex-col items-end">
												{#if data["promos"].length == 0}
													<p class="text-md text-red-500 font-medium">
														- {Util.formatRupiah(data["totalDiscount"], "Rp.")}
													</p>
												{/if}
											</div>
										</div>
										{#each data["promos"] as promo}
											{#each promo["used"] as promoUsed}
												<div class="flex justify-between">
													<h4
														class="text-sm text-gray-800 font-medium ml-3 pl-3 border-l-2 border-red-500"
													>
														{promo["name"]}
													</h4>
													<div class="flex flex-col items-end">
														{#if promoUsed["bonus"].length == 0}
															{#if promoUsed["discount"] != 0}
																<p class="text-md text-red-500 font-medium">
																	- {Util.formatRupiah(
																		parseInt(promoUsed["discount"]),
																		"Rp.",
																	)}
																</p>
															{/if}
														{/if}
														{#if promoUsed["bonus"].length > 0}
															<div class="flex flex-col space-y-2 items-end">
																{#each promoUsed["bonus"] as bonus}
																	<p class="text-md text-green-500 font-medium">
																		+ Free {bonus["qty"]}
																		{bonus["name"]}
																	</p>
																{/each}
															</div>
														{/if}
													</div>
												</div>
											{/each}
										{/each}
										<div class="flex justify-between">
											<h4 class="text-sm text-gray-800 font-medium">
												Store Discount
											</h4>
											<div class="flex flex-col items-end">
												<p class="text-md text-red-500 font-medium">
													- {data["totalStoreDiscount"]
														? Util.formatRupiah(
																parseInt(data["totalStoreDiscount"]),
																"Rp.",
														  )
														: "Rp. 0"}
												</p>
											</div>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-sm text-gray-800 font-medium">
												Service Charge
											</h4>
											<p class="text-md text-gray-800 font-medium">
												{Util.formatRupiah(
													parseInt(data["serviceCharge"]),
													"Rp .",
												)}
											</p>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-sm text-gray-800 font-medium">Tax</h4>
											<p class="text-md text-gray-800 font-medium">
												{Util.formatRupiah(parseInt(data["tax"]), "Rp .")}
											</p>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-sm text-gray-800 font-medium">
												Rounding
											</h4>
											<p class="text-md text-gray-800 font-medium">
												{data["roundingType"] == "UP"
													? Util.formatRupiah(
															parseInt(data["rounding"]) || 0,
															"Rp. ",
													  )
													: "(" +
													  Util.formatRupiah(
															parseInt(data["rounding"]) || 0,
															"Rp. ",
													  ) +
													  ")"}
											</p>
										</div>
										<hr class="my-3" />
										<div class="flex justify-between items-center mb-2">
											<h4 class="text-md text-gray-800 font-medium">Total</h4>
											<p class="text-md text-gray-800 font-medium">
												{Util.formatRupiah(data["total"], "Rp .")}
											</p>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-md text-gray-800 font-medium">
												{data["paymentType"] == "CREDIT"
													? "DEBIT/CREDIT"
													: data["paymentType"]}
											</h4>
											<p class="text-md text-gray-800 font-medium">
												{Util.formatRupiah(
													data["paymentDetail"]["paymentValue"],
													"Rp .",
												)}
											</p>
										</div>
										<div class="flex justify-between items-center">
											<h4 class="text-md text-gray-800 font-medium">
												Kembalian
											</h4>
											<p class="text-md text-gray-800 font-medium">
												{Util.formatRupiah(
													data["paymentDetail"]["paymentValue"] - data["total"],
													"Rp .",
												)}
											</p>
										</div>
										{#if data["requestToVoid"]}
											<hr class="my-3" />
											<div class="flex flex-col">
												<h4 class="text-md text-gray-800 font-medium">
													Void Remarks
												</h4>
												<p class="text-md text-gray-800">
													{data["remarks"]}
												</p>
											</div>
										{/if}
									</div>
								</div>
							{/if}
						{/each}
					</div>
				</div> -->
        </div>
      </div>
    </div>
  </div>
</Layout>

<style>
  :global(.svelte-tabs li.svelte-tabs__selected) {
    color: #9c6039;
    border-bottom: 4px solid #9c6039;
  }
  :global(.svelte-tabs li.svelte-tabs__tab) {
    color: #999999;
    font-size: 12px;
    font-weight: bold;
    padding: 15px;
    text-transform: uppercase;
  }
</style>
